html, body, #app {
  min-height: 100%;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

.containerYoutube .modal-dialog {
  top: 50%;
  left: 50%;
  width: max-content;
  height: max-content;
  margin: 0;
  transform: translate(-50%, -60%) !important;
  max-width: unset !important;
}

.containerYoutube .closeBtn {
  position: absolute;
  top: -38px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  color: #FFF;
  padding: 7px 15px;
  border-radius: 10px;
  font-weight: bold;
  border: 0;
}

.containerYoutube .modal-content {
  width: max-content !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

@media(max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media(max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

body {
  background: linear-gradient(180deg, #236E65 23.44%, #0E4378 100%);
  color: #606E80;
}

body, input textarea, button {
  font: 400 1rem "inter", sans-serif;
}

button {
  cursor: pointer
}

a {
  color: inherit;
  text-decoration: none;
}