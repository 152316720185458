.HeaderContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.HeaderContent > span {
  text-align: center;
}

.HeaderContent > span:last-child {
  margin-bottom: 4%;
}


.forgotForm > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgotForm Button:last-child{
  margin-bottom: 5.2rem;
}
