.Leasson {
  flex: 1;
  flex-direction: column;
  margin-bottom: 40px
}

.LeassonContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: -10px;
  background: #FFFFFF;
  border-radius: 0px 0px 15px 15px;
  padding: 2rem;
  border: 1px solid #7BB0E5;
}

.LeassonContent {
  width: 100%;
  border-radius: 15px;
}

.LeassonContent>div>div:last-child button {
  border-radius: 7px;
  width: 195px;
  height: 55px;
  border: 0;
  font-size: 18.5;
  font-weight: bold;
  color: #FFF;
  margin-top: 35.47px;
}

.LeassonContent>div h1 {
  font-size: 22px;
  font-weight: bold;
  color: #373F49;
  margin-bottom: 16px;
}

.LeassonContent>div:last-child span {
  font-size: 12px;
}

.LeassonContent>div span {
  font-size: 12px;
  font-weight: 400;
}

.LeassonContent>div>div:last-child {
  text-align: right;
}

.LeassonContent>div>div:last-child span {
  display: block;
}

.LeassonDetails>div>div:nth-child(2) {
  font-family: Inter;
  font-size: 14px;
  line-height: 15px;
  color: #000;
  margin-top: 25px;
}

.LeassonDetails>div>div:nth-child(3) {
  font-family: Inter;
  font-size: 14px;
  line-height: 15px;
  color: #000;
  margin-top: 40px;
}

.LeassonDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leasson-finished span {
  font-family: Inter !important;
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 29px !important;
  color: #3EC1B1 !important;
  /* margin-top: 35px; */
}

.leasson-finished button {
  background: #3EC1B1;
  font-size: 18px;
  line-height: 22px;
}

.leasson-in-progress button:first-child {
  background: unset !important;
  color: #FF5722 !important;
  border: 1px solid #FF5722 !important;
  font-family: Inter;
  font-size: 18px;
  line-height: 22px;
}

.leasson-in-progress button:last-child {
  background: #1976D2;
  margin-top: 10px !important;
}

@media(max-width: 980px) {
  .LeassonDetails>div>div:nth-child(1) {
    text-align: center;
    white-space: initial;
  }
  .LeassonDetails>div>div:nth-child(2) {
    text-align: center;
  }
  .LeassonDetails>div>div:nth-child(3) {
    justify-content: center;
  }
  .LeassonContent>div>div:first-child {
    text-align: center;
  }
  .LeassonContent>div>div:last-child {
    text-align: center;
  }
}