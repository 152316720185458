.TermsSection {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.TermsContent {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px;
}

.TermsMain {
  flex: 1;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

.TermsMain > div  {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.SectionTermsContainer {
  width: 100%;
  max-width: 770px;
  background: #ffffff;
  box-shadow: 0px 55px 32px -10px #0a325a;
  border-radius: 9px;
  padding: 30px;
  height: 40.25rem;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.SectionTermsContainer > div {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;


}

.SectionTermsContainer .termsContainer {
  margin-top: 2.25rem;

  padding: 20px;
  max-height: 330px;
}

.SectionTermsContainer .termsContainer > div {
  height: 100%;
  overflow: auto;



}


.SectionTermsContainer .acceptContainer {
  margin-top: 1.25rem;

  padding: 20px;

  display: flex;
  justify-content: flex-start !important;

  color: #434D59;
  font-size: 14px;
}

.SectionTermsContainer .buttonsContainer {
  display: flex;
  justify-content: flex-start !important;
}


.SectionTermsContainer .buttonsContainer button {
  margin-left: 10px;
}



@media (max-width: 920px) {

  .TermsMain > div  {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px){
  .TermsMain > div  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 720px){
  .SectionTermsContainer  {
    height: 50.25rem;
    max-width: 350px;
  }
}

@media (max-width: 430px){
  .SectionTermsContainer  {
    height: 50rem;
    width: 420px;
  }
}


.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  height: 50px;

  font-size: 12px;
  color: #FFF;
}



.SectionTermsContainer .termsContainer > div::-webkit-scrollbar {
  width: 5px;
  height: 5px;
 }
 .SectionTermsContainer .termsContainer > div::-webkit-scrollbar-thumb {
  background: #e1e2e4;
  border-radius: 6px;
 }

 .SectionTermsContainer .termsContainer > div::-webkit-scrollbar-track{
  background: #ffffff;
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
 }
