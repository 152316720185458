.Lesson {
  flex: 1;
  flex-direction: column;
  margin-bottom: 40px
}

.noShow {
  display: none;
}

.Lesson .dropdownContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  border: 0;
  justify-content: flex-end;
  background: #DEEBF8;
  border-radius: 15px 15px 15px 15px;
  margin-top: 0px;
  padding: 20px 12px 16px 16px;
}

.Lesson .dropdownContainer>button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  background: transparent;
}

.Lesson .dropdownContainerExpand svg {
  transform: rotate(-180deg);
}

.Lesson .dropdownContainer>button>span {
  width: 97%;
  display: flex;
  margin-right: 9px;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.Lesson .dropdownContainer button>span>svg {
  margin: 0 1rem;
}

.LessonContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  background: #FFFFFF;
  border-radius: 15px 15px 15px 15px;
  padding: 2rem;
  border: 1px solid #7BB0E5;
}

.LessonContent {
  width: 100%;
  border-radius: 15px;
}

.LessonContent>div>div:last-child button {
  background: #1976D2;
  border-radius: 7px;
  width: 195px;
  height: 55px;
  border: 0;
  font-size: 18.5;
  font-weight: bold;
  color: #FFF;
  margin-top: 89.47px;
}

.basic-button {
  background: #3EC1B1;
  border-radius: 7px;
  width: 195px;
  height: 55px;
  border: 0;
  font-size: 18.5;
  font-weight: bold;
  color: #FFF;
  margin-top: 35px;
}

.LessonContent>div h1 {
  font-size: 22px;
  font-weight: bold;
  color: #373F49;
  margin-bottom: 16px;
}

.LessonContent>div:last-child span {
  font-size: 12px;
}

.LessonContent>div span {
  font-size: 12px;
  font-weight: 400;
}

.LessonContent>div>div:last-child {
  text-align: right;
}

.LessonContent>div>div:last-child span {
  display: block;
}

.LessonDetails div:last-child span {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
}

/* .LessonDetails>div>div:first-child { */

/* white-space: nowrap; */

/* } */

.LessonDetails>div>div:nth-child(2) {
  font-family: Inter;
  font-size: 12px;
  line-height: 15px;
  color: #000;
}

.LessonDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LessonDetails>div:last-child {
  display: flex;
  align-items: center;
}

.LessonDetails>div:last-child img {
  margin-right: 8px;
}

.lessonsTableSection {
  margin-top: 5%;
}

.lessonsTableContainer {
  width: 100%;
  padding: 5px;
  display: grid;
  grid-template-columns: 43% 30% 20%;
  grid-gap: 2.1rem;
  color: #373F49;
  position: relative;
}

.lessonsTableContainer+.lessonsTableContainer {
  margin-top: 10px;
}

.lessonsTableContainer:after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #BDD7F2;
  width: 100%;
  transform: translateX(-50%);
  bottom: -10px;
  left: 50%;
}

.lessonsTableContainer div {
  font-size: 16px;
}

.lessonsTableContainer div:first-child {
  margin-left: 27px;
}

.lessonsTableContainer div:last-child {
  font-weight: bold;
  color: #3EC1B1;
}

.timer-type-in-Time {
  display: block;
  margin-top: 50px;
}

.timer-type-in-Time h4 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.timer-type-one-hour {
  white-space: nowrap;
  display: inline-flex;
  position: relative;
  margin-top: 70px;
}

.timer-type-started {
  white-space: nowrap;
  display: inline-flex;
  position: relative;
  margin-top: 70px;
}

.timer-type-started span {
  margin-left: 15px;
  font-family: Inter;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 17px;
  color: #FF5722 !important;
  display: flex;
  align-items: center;
}

.btn-in-time button {
  background: #ADC3D9;
}

.btn-one-hour button {
  background: #1976D2;
}

.btn-started button {
  background: #FF5722;
}

.leasson-finished {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.leasson-finished span {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #3EC1B1 !important;
}

.leasson-progress {
  display: inline-flex;
  margin-top: 70px;
}

.leasson-progress span {
  margin-left: 15px;
  font-family: Inter;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 17px;
  color: #000 !important;
  display: flex;
  align-items: center;
}

.progress {
  width: 100%;
}

.progress-bar {
  background-color: #3EC1B1;
}

@media(max-width: 980px) {
  .LessonDetails>div>div:nth-child(1) {
    text-align: center;
    white-space: initial;
  }
  .LessonDetails>div>div:nth-child(2) {
    text-align: center;
  }
  .LessonDetails>div>div:nth-child(3) {
    justify-content: center;
  }
  .LessonContent>div>div:first-child {
    text-align: center;
  }
  .LessonContent>div>div:last-child {
    text-align: center;
  }
  .leasson-btn-certifield, .timer-type-in-Time {
    text-align: center;
  }
}