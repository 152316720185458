.HelpCenterSection {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.HelpCenterContent {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px;
}

.HelpCenterMain {
  flex: 1;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

.loading-align {
  text-align: center;
}

.HelpCenterMain>div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SectionHelpCenterContainer {
  width: 100%;
  max-width: 770px;
  background: #ffffff;
  box-shadow: 0px 55px 32px -10px #0a325a;
  border-radius: 9px;
  padding: 30px;
  /* height: 40.25rem; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.SectionHelpCenterContainer>div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.HelpCenterCardsContainer {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ContentHelpCenterStyle {
  flex: 1;
  flex-direction: column;
}

.noShow {
  display: none;
}

.ContentHelpCenterStyle .dropdownContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 45px;
  border: 0;
  justify-content: flex-end;
  background: #DEEBF8;
  border-radius: 0px 0px 15px 15px;
  margin-top: 5px;
  padding: 5px;
}

.ContentHelpCenterStyle .dropdownContainer>button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  background: transparent;
}

.ContentHelpCenterStyle .dropdownContainerExpand svg {
  transform: rotate(-180deg);
}

.dropdownContainerExpand {
  display: block !important;
}

.ContentHelpCenterStyle .dropdownContainer>button>span {
  width: 97%;
  display: flex;
  margin-right: 9px;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.ContentHelpCenterStyle .dropdownContainer button>span>svg {
  margin: 0 1rem;
}

.ContentHelpCenterStyleContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: #FFF;
  margin-top: 1rem;
  margin-bottom: -10px;
  background: #1976D2;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #7BB0E5;
}

.ContentHelpCenterStyleContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1.25rem;
  border-radius: 15px;
}

@media(max-width: 980px) {
  .ContentHelpCenterStyle {
    margin-bottom: 1.52rem;
  }
  .ContentHelpCenterStyleContent {
    flex-direction: column;
  }
  .ContentHelpCenterStyleDetails>div {
    text-align: center;
    margin-top: 1.25rem;
  }
  .ContentHelpCenterStyleContent>div:last-child button {
    margin-top: 32px;
    background: #1976D2;
    border-radius: 7px;
    width: 195px;
    height: 55px;
    border: 0;
    font-size: 18.5;
    font-weight: bold;
    color: #FFF;
  }
  .ContentHelpCenterStyleContent>div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ContentHelpCenterStyleContent>div span {
    margin-top: 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
  }
  .ContentHelpCenterStyleDetails div:last-child span {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
  }
  .ContentHelpCenterStyleDetails div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.ContentHelpCenterStyleContent>div {
  margin-left: 1.25rem;
}

.ContentHelpCenterStyleContent>div:last-child {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: space-between;
}

.ContentHelpCenterStyleContent>div:last-child button {
  background: #1976D2;
  border-radius: 7px;
  width: 195px;
  height: 55px;
  border: 0;
  font-size: 18.5;
  font-weight: bold;
  color: #FFF;
}

.ContentHelpCenterStyleContent>div h1 {
  font-size: 22px;
  font-weight: bold;
  color: #373F49;
  margin-bottom: 16px;
}

.ContentHelpCenterStyleContent>div:last-child span {
  font-size: 12px;
}

.ContentHelpCenterStyleContent>div span {
  font-size: 12px;
  font-weight: 400;
}

.ContentHelpCenterStyleDetails div:last-child span {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
}

.ContentHelpCenterStyleDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ContentHelpCenterStyleDetails>div:last-child {
  display: flex;
  align-items: center;
}

.ContentHelpCenterStyleDetails>div:last-child img {
  margin-right: 8px;
}

.helpCenterStyleTableSection {
  margin-top: 1%;
  background: transparent;
}

.helpCenterStyleTableContainer {
  width: 100%;
  padding: 15px 35px;
  border-left: 1px solid #BDD7F2;
  border-right: 1px solid #BDD7F2;
  border-bottom: 1px solid #BDD7F2;
  border-radius: 6px;
  color: #606E80;
  position: relative;
  display: none;
}

.helpCenterStyleTableContainer+.helpCenterStyleTableContainer {
  margin-top: 10px;
}

.containerHtml>p>img {
  width: 100%;
}

@media (max-width: 920px) {
  .HelpCenterMain>div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .HelpCenterMain>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 720px) {
  .SectionHelpCenterContainer {
    /* height: 50.25rem; */
    max-width: 350px;
  }
}

@media (max-width: 430px) {
  .SectionHelpCenterContainer {
    /* height: 50rem; */
    width: 420px;
  }
}

.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  font-size: 12px;
  color: #FFF;
}

.SectionHelpCenterContainer .termsContainer>div::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.SectionHelpCenterContainer .termsContainer>div::-webkit-scrollbar-thumb {
  background: #e1e2e4;
  border-radius: 6px;
}

.SectionHelpCenterContainer .termsContainer>div::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}