.SigninContainer {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  min-height: 100vh;
}

.Content {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  padding: 60px;
}

.Main {
  flex: 1;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

.Main > div  {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.loginContainer {
  width: 100%;
  max-width: 370px;
  background: #ffffff;
  box-shadow: 0px 55px 32px -10px #0a325a;
  border-radius: 9px;
  padding: 30px;
  height: 40.25rem;
}

.loginContainer .form {
  margin-top: 1.1rem;
}

.loginContainer a {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #1976d2;
  text-decoration: none;
}

.loginType {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loginLocale {
  margin-top: 1.25rem;
}

.loginLocale > div svg {
  vertical-align: middle;
  margin-right: 9px;
}

.loginLocale > div span {
  font-size: 14px;
  line-height: 15px;
}

.loginLocale > div {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.RememberContainer {
  margin-top: 2.25rem;
  display: flex;
  align-items: center;
}

.dataInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  margin: 1rem;
}

.dataInfo:first-child > span {
  font-size: 80px;
  color: #FFF;
  padding: 10px;

}

.dataInfo:last-child > span {
  font-size: 32px;
  color: #FFF;
  width: 300px;
}

@media (max-width: 920px) {
  .dataInfo {
    display: none;
  }

  .Main > div  {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px){
  .Main > div  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dataInfo:last-child {
    margin-top: 40px;
  }

}

@media (max-width: 720px){
  .loginContainer  {
    height: 43.25rem;
    max-width: 350px;
  }
}

@media (max-width: 430px){

  .loginContainer  {
    height: 46.25rem;

    width: 420px;
  }

  .loginLocale > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loginLocale > div > button + button {
    margin-top: 10px;
  }
}

.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  height: 50px;

  font-size: 12px;
  color: #FFF;
}
