.Cfc {
  flex: 1;
  flex-direction: column;
  margin-bottom: 40px
}

.noShow {
  display: none;
}

.CfcDetails>div>div:nth-child(1) h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #373F49;
}

.destaque-subtitle {
  font-family: Inter;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.CfcDetails>div>div:nth-child(2) {
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  margin-top: 24px;
  color: #373F49;
}

.CfcContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: -10px;
  background: #FFFFFF;
  border-radius: 15px;
  padding: 2rem;
  border: 1px solid #7BB0E5;
}

.button-container {
  border-radius: 7px;
  width: 195px;
  height: 55px;
  border: 0;
  font-size: 18.5;
  font-weight: bold;
  color: #FFF;
  margin-top: 48px;
}

.CfcContent>div>div:nth-child(1) {
  text-align: center;
}

.CfcContent>div>div:nth-child(1) img {
  height: 118px;
  text-align: center;
}

.CfcContent>div h1 {
  font-size: 22px;
  font-weight: bold;
  color: #373F49;
  margin-bottom: 16px;
}

.started-in {
  font-size: 12px !important;
  font-weight: 400 !important;
  display: block !important;
}

.CfcContent>div>div:last-child {
  text-align: right;
}

.history-progress {
  display: inline-flex;
  margin-top: 30px;
}

.history-progress span {
  margin-left: 15px;
  font-family: Inter;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 17px;
  color: #000 !important;
  display: flex;
  align-items: center;
}

.progress {
  width: 100%;
}

.progress-bar {
  background-color: #3EC1B1;
}

/* .history-finished-text {
  margin-top: 30px;
} */

.history-finished-text span {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
  color: #FF5722 !important;
}

.with-timer {
  display: flex;
  justify-content: flex-end;
}

.with-timer>div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.with-timer>div>div {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
}

.with-timer>div>div>span {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
}

.countdownContainer>span {
  font-family: Inter;
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: 30px;
  color: #000 !important;
  margin-top: 2px;
}

.with-timer .button-container {
  margin-top: 20px;
}

.orange {
  background: #FF5722;
}

.success {
  background: #3EC1B1;
}

.light-gray {
  background: #ADC3D9;
}

.status-text-blue {
  color: #1976D2 !important;
}

.button-container img {
  margin-right: 9px;
}

.open-class .button-container {
  margin-top: 10px;
}

.open-class .button-container:first-child {
  margin-top: 0px;
}

@media(max-width: 980px) {
  .CfcDetails>div>div:nth-child(1) {
    text-align: center;
    white-space: initial;
  }
  .CfcDetails>div>div:nth-child(2) {
    text-align: center;
  }
  .CfcDetails>div>div:nth-child(3) {
    justify-content: center;
  }
  .CfcContent>div>div:last-child {
    text-align: center;
  }
  .open-class .button-container:first-child {
    margin-top: 20px;
  }
  .with-timer {
    margin-top: 20px;
    justify-content: center;
  }
}