.Course {
  flex: 1;
  flex-direction: column;
  margin-bottom: 40px
}

.noShow {
  display: none;
}

.Course .dropdownContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  border: 0;
  justify-content: flex-end;
  background: #DEEBF8;
  border-radius: 15px 15px 15px 15px;
  margin-top: 0px;
  padding: 20px 12px 16px 16px;
}

.Course .dropdownContainer>button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  background: transparent;
}

.Course .dropdownContainerExpand svg {
  transform: rotate(-180deg);
}

.Course .dropdownContainer>button>span {
  width: 97%;
  display: flex;
  margin-right: 9px;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.Course .dropdownContainer button>span>svg {
  margin: 0 1rem;
}

.CourseContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: -10px;
  background: #FFFFFF;
  border-radius: 15px 15px 15px 15px;
  padding: 2rem;
  border: 1px solid #7BB0E5;
}

.CourseContent {
  width: 100%;
  border-radius: 15px;
}

.CourseContent>div>div:last-child button {
  border-radius: 7px;
  width: 195px;
  height: 55px;
  border: 0;
  font-size: 18.5px;
  font-weight: bold;
  color: #FFF;
  margin-top: 89.47px;
}

.CourseContent>div h1 {
  font-size: 22px;
  font-weight: bold;
  color: #373F49;
  margin-bottom: 16px;
  white-space: normal;
}

.CourseContent>div:last-child span {
  font-size: 12px;
}

.CourseContent>div span {
  font-size: 12px;
  font-weight: 400;
}

.CourseContent>div>div:last-child {
  text-align: right;
}

.CourseContent>div>div:last-child span {
  display: block;
}

.CourseDetails div:last-child span {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
}

.CourseDetails>div>div:first-child {
  white-space: nowrap;
}

.CourseDetails>div>div:nth-child(2) {
  font-family: Inter;
  font-size: 12px;
  line-height: 15px;
  color: #000;
}

.CourseDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CourseDetails>div:last-child {
  display: flex;
  align-items: center;
}

.CourseDetails>div:last-child img {
  margin-right: 8px;
}

.coursesTableSection {
  margin-top: 5%;
}

.coursesTableContainer {
  width: 100%;
  padding: 5px;
  display: grid;
  grid-template-columns: 43% 30% 20%;
  grid-gap: 2.1rem;
  color: #373F49;
  position: relative;
}

.coursesTableContainer+.coursesTableContainer {
  margin-top: 10px;
}

.coursesTableContainer:after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #BDD7F2;
  width: 100%;
  transform: translateX(-50%);
  bottom: -10px;
  left: 50%;
}

.coursesTableContainer div {
  font-size: 16px;
}

.coursesTableContainer div:first-child {
  margin-left: 27px;
}

.coursesTableContainer div:last-child {
  font-weight: bold;
  color: #3EC1B1;
}

.timer-type-in-Time {
  display: block;
  margin-top: 50px;
}

.timer-type-in-Time h4 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.timer-type-one-hour {
  white-space: nowrap;
  display: inline-flex;
  position: relative;
  margin-top: 70px;
}

.timer-type-one-hour span {
  width: 210px;
}

.timer-type-started {
  white-space: nowrap;
  display: inline-flex;
  position: relative;
  margin-top: 70px;
}

.timer-type-started span {
  margin-left: 15px;
  font-family: Inter;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 17px;
  color: #FF5722 !important;
  display: flex;
  align-items: center;
}

.btn-started>a>button {
  background: #FF5722;
}

.btn-one-hour>a>button {
  background: #1976D2;
}

@media(max-width: 980px) {
  .CourseDetails>div>div:nth-child(1) {
    text-align: center;
    white-space: initial;
  }
  .CourseDetails>div>div:nth-child(2) {
    text-align: center;
  }
  .CourseDetails>div>div:nth-child(3) {
    justify-content: center;
  }
  .CourseContent>div>div:first-child {
    text-align: center;
  }
  .CourseContent>div>div:last-child {
    text-align: center;
  }
}